import { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import axios from "axios";
import ReportQuestionDialog from "./ReportQuestionDialog";
import ExamSummary from "./ExamSummary";
import QuestionGrid from "./QuestionGrid";
import StandardSingleAnswerQuestionComponent from "./questions/StandardSingleAnswerQuestionComponent";
import StandardMultipleAnswerQuestionComponent from "./questions/StandardMultipleAnswerQuestionComponent";
import InstantFeedbackComponent from "./questions/InstantFeedbackComponent";
import {
  mutateQuestion,
  pauseCurrentExam,
  finishCurrentExam,
  updateCurrentExam,
  setQuestionToReport,
} from "../../../store/reducers/appSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const StandardExam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentExam = JSON.parse(
    JSON.stringify(useSelector((store) => store.app.currentExam))
  );
  const questionToReport = useSelector((store) => store.app.questionToReport);
  const cert = useSelector((store) => store.app.cert);
  const location = useLocation();
  const pastExamIndex = location.state?.pastExamIndex || 0;
  const viewPastExam = location.state?.viewPastExam || false;

  const { timed, instantFeedback } = currentExam.settings;
  const questions = currentExam.questions;

  const [examFinished, setExamFinished] = useState(
    pastExamIndex >= 0 && viewPastExam ? true : false
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(
    currentExam.settings.timeRemaining
  );
  const [minutes, setMinutes] = useState(
    Math.floor(currentExam.settings.timeRemaining / 60)
  );
  const [seconds, setSeconds] = useState("00");
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [reportResponseMessage, setReportResponseMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPauseAlert, setShowPauseAlert] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [showInstantFeedback, setShowInstantFeedback] = useState(false);
  const [invalidReportAlertMessage, setInvalidReportAlertMessage] =
    useState("");
  const [scrollY, setScrollY] = useState(0);

  const handleClickOpenReportDialog = (question) => {
    dispatch(setQuestionToReport(question));
    setReportResponseMessage("");
    setReportReason("");
    setInvalidReportAlertMessage("");
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setReportReason("");
    dispatch(setQuestionToReport({}));
    setOpenReportDialog(false);
  };

  const handleReportReasonChange = (value) => {
    setReportReason(value);
  };

  const handleSubmitReport = async () => {
    const URL =
      `https://0sxyh5cksj.execute-api.us-east-1.amazonaws.com/question/report?id=${questionToReport.ID}&cert=${questionToReport.CERT}&report_reason=${reportReason}&ts=` +
      Date.now();

    let response = await axios.get(URL);

    if (response.data) {
      setReportResponseMessage(response.data.msg);
    }

    setTimeout(() => {
      setOpenReportDialog(false);
    }, 3000);
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setShowInstantFeedback(false);
      if (
        questions[currentQuestionIndex] &&
        !questions[currentQuestionIndex]?.hasOwnProperty("USER_ANSWERS")
      ) {
        questions[currentQuestionIndex].SKIPPED = true;
        questions[currentQuestionIndex].ANSWERED = false;
      }

      dispatch(
        mutateQuestion({
          index: currentQuestionIndex,
          question: questions[currentQuestionIndex],
        })
      );
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setShowInstantFeedback(false);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const pressSubmitButton = () => {
    let allAnswered = true;
    allAnswered &&
      questions.forEach((question) => {
        if (question && !question.hasOwnProperty("USER_ANSWERS")) {
          allAnswered = false;
        }
      });
    if (!allAnswered) {
      setShowAlert(true);
      return;
    }

    finishExam();
  };

  const pressPauseButton = () => {
    setShowPauseAlert(true);
  };

  const finishExam = () => {
    currentExam.settings.numCorrect = 0;

    questions.forEach((question, index) => {
      if (question && !question?.hasOwnProperty("USER_ANSWERS")) {
        question.USER_ANSWERS = [];
        question.SKIPPED = true;
      } else checkAnswer(question, index);
    });

    questions.forEach((question) => {
      if (question.CORRECT) {
        currentExam.settings.numCorrect += 1;
      }
    });

    currentExam.settings.finished = true;

    dispatch(updateCurrentExam(currentExam));
    dispatch(finishCurrentExam());
    setExamFinished(true);
  };

  const checkAnswer = (question, index) => {
    let tempQuestion = currentExam.questions[index];
    if (isCorrect(question)) {
      tempQuestion.SKIPPED = false;
      tempQuestion.CORRECT = true;
      tempQuestion.ANSWERED = true;
    } else {
      tempQuestion.SKIPPED = false;
      tempQuestion.CORRECT = false;
      tempQuestion.ANSWERED = true;
    }

    currentExam.questions[index] = tempQuestion;
  };

  const isCorrect = (question) => {
    return (
      question.ANSWERS.filter((answer) => answer.CORRECT).length ===
        question.USER_ANSWERS.length &&
      question.USER_ANSWERS.every((answer) => answer.CORRECT)
    );
  };

  const pauseExam = () => {
    timed
      ? (currentExam.settings.timeRemaining =
          parseInt(minutes) * 60 + parseInt(seconds))
      : (currentExam.settings.timeRemaining = null);
    currentExam.settings.paused = true;
    currentExam.settings.timestamp = Date.now();
    currentExam.settings.finished = false;
    currentExam.settings.currentQuestionIndex = currentQuestionIndex;
    currentExam.settings.cert = cert.name;
    currentExam.settings.certId = cert.id;

    setShowPauseAlert(false);
    navigate("/");
    dispatch(pauseCurrentExam(currentExam));
  };

  const computeColor = (question) => {
    if (question.SKIPPED) return "#9e9e9e";
    if (isCorrect(question)) {
      return "#4caf50";
    } else {
      return "#f44336";
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return {
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (timed && timeRemaining >= 0 && !examFinished) {
      const interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime > 0) {
            const { minutes, seconds } = formatTime(prevTime - 1);
            setMinutes(minutes);
            setSeconds(seconds);
            return prevTime - 1;
          } else {
            finishExam();
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timed, examFinished]);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <CardContent
      sx={{
        width: { sx: "85vw", md: "45vw" },
        backgroundColor: "#22272b",
        color: "white",
        borderRadius: 3,
        marginTop: 3,
        marginBottom: 50,
        marginRight: 2,
        marginLeft: 2,
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: 25, textAlign: "center" }} gutterBottom>
        {cert.name} Practice Exam
      </Typography>

      {currentExam?.settings?.id && (
        <div>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClickOpenReportDialog.bind(
                this,
                questions[currentQuestionIndex]
              )}
              sx={{
                color: "white",
                cursor: "pointer",
              }}
            >
              Report Question
            </Button>
          </span>
          <br />
        </div>
      )}

      <ReportQuestionDialog
        openReportDialog={openReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
        handleSubmitReport={handleSubmitReport}
        reportResponseMessage={reportResponseMessage}
        currentQuestionIndex={currentQuestionIndex}
        handleReportReasonChange={handleReportReasonChange}
        reportReason={reportReason}
        invalidReportAlertMessage={invalidReportAlertMessage}
        setInvalidReportAlertMessage={setInvalidReportAlertMessage}
      />

      <QuestionGrid
        questions={questions}
        currentQuestionIndex={currentQuestionIndex}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
      />

      <br />

      {timeRemaining >= -1 ? (
        <Typography
          sx={{ textAlign: "center" }}
          variant="body1"
          component="div"
        >
          Time Remaining: {minutes}:{seconds}
        </Typography>
      ) : null}

      <hr width="100%" />
      <br />

      {!examFinished ? (
        <span>
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" component="div">
              Question {currentQuestionIndex + 1} of {questions.length}:
            </Typography>

            <span>
              <Button
                sx={{ color: "white !important", marginRight: 1 }}
                onClick={pressPauseButton}
                variant="contained"
                color="warning"
              >
                Pause
              </Button>

              <Button
                sx={{ color: "white !important" }}
                onClick={pressSubmitButton}
                variant="contained"
              >
                Submit
              </Button>
            </span>
          </span>
          {showAlert && (
            <Box>
              <br />
              <Alert
                severity="warning"
                variant="standard"
                action={
                  <Grid container>
                    <Button
                      onClick={finishExam}
                      size="small"
                      variant="contained"
                    >
                      Yes
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => setShowAlert(false)}
                    >
                      No
                    </Button>
                  </Grid>
                }
              >
                <Typography variant="body1" component="div">
                  "It looks like you've skipped some questions. Are you sure you
                  want to submit?"
                </Typography>
              </Alert>
              <br />
            </Box>
          )}

          {showPauseAlert && (
            <span>
              <br />
              <Alert
                severity="info"
                variant="standard"
                action={
                  <Grid container>
                    <Button
                      onClick={pauseExam}
                      size="small"
                      variant="contained"
                    >
                      Yes
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => setShowPauseAlert(false)}
                    >
                      No
                    </Button>
                  </Grid>
                }
              >
                Are you sure you want to pause the exam?
              </Alert>
              <br />
            </span>
          )}

          <br />
          <Typography variant="body1" component="div">
            {questions[currentQuestionIndex]?.PROMPT}
          </Typography>
          <br />

          {questions[currentQuestionIndex]?.TYPE.includes("multiple-answer") ? (
            <StandardMultipleAnswerQuestionComponent
              currentQuestionIndex={currentQuestionIndex}
              question={questions[currentQuestionIndex]}
            />
          ) : (
            <StandardSingleAnswerQuestionComponent
              currentQuestionIndex={currentQuestionIndex}
              question={questions[currentQuestionIndex]}
            />
          )}

          <br />
          {instantFeedback && showInstantFeedback ? (
            <InstantFeedbackComponent
              question={questions[currentQuestionIndex]}
              computeColor={computeColor}
            />
          ) : null}
          <br />

          {instantFeedback && (
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setShowInstantFeedback(!showInstantFeedback);
              }}
            >
              {showInstantFeedback ? "Hide" : "Show"} Instant Feedback
            </Button>
          )}
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              disabled={currentQuestionIndex === 0}
              color="warning"
              variant="contained"
              onClick={previousQuestion}
              sx={{ color: "lightgray !important", marginRight: 1 }}
            >
              Back
            </Button>
            <Button
              sx={{ color: "white !important" }}
              disabled={currentQuestionIndex === questions.length - 1}
              onClick={nextQuestion}
              color="success"
              variant="contained"
            >
              Next
            </Button>
          </div>
        </span>
      ) : (
        <ExamSummary
          questions={questions}
          computeColor={computeColor}
          handleClickOpenReportDialog={handleClickOpenReportDialog}
          pastExamIndex={pastExamIndex}
          isCorrect={isCorrect}
        />
      )}
      {examFinished && scrollY > 2000 && (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          sx={{ position: "fixed", right: 10, top: 50 }}
          onClick={scrollToTop}
        >
          Scroll To Top
        </Button>
      )}
    </CardContent>
  );
};

export default StandardExam;
