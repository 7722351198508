import aPlusSvg from "../../../../assets/comptia-aplus.svg";

const a_plus_core_1 = {
  id: "a-plus",
  name: "CompTIA A+ (220-1101)",
  description: "CompTIA A+",
  icon: aPlusSvg,
  questions: 65,
  time: 5400,
  exam_id: "220-1101",
  topics: [
    "Mobile-Devices",
    "Networking",
    "Hardware",
    "Virtualization-and-Cloud-Computing",
    "Hardware-and-Network-Troubleshooting",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "220-1101" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value:
          "12 months of hands-on experience in a help desk support technician, desktop support technician, or field service technician job role",
      },
      { name: "Passing Score", value: "675 (on a scale of 100–900)" },
      { name: "Cost of Exam", value: "$253.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      { number: "1.0", name: "Mobile Devices", percentage: "15%" },
      { number: "2.0", name: "Networking", percentage: "20%" },
      { number: "3.0", name: "Hardware", percentage: "25%" },
      {
        number: "4.0",
        name: "Virtualization and Cloud Computing",
        percentage: "11%",
      },
      {
        number: "5.0",
        name: "Hardware and Network Troubleshooting",
        percentage: "29%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA A+ 220-1101 exam objectives:",
    more_domain_info_link:
      "https://partners.comptia.org/docs/default-source/resources/comptia-a-220-1101-exam-objectives-(3-0)",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/a-certification/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Professor Messer",
        provider_link_available: true,
        provider_link:
          "https://www.professormesser.com/free-a-plus-training/220-1101/220-1101-video/220-1101-training-course/",
        free: true,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/comptia-a-core-1",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/comptia-a-core-1-220-1101",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Udemy",
        provider_link_available: true,
        provider_link: "https://www.udemy.com/comptia-a-core-1-220-1101/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PowerCert Animated Videos",
        provider_link_available: true,
        provider_link: "https://www.youtube.com/watch?v=2eLe7uz-7CM",
        free: true,
        video_content: true,
        practice_exams: false,
      },
    ],
  },
};

export default a_plus_core_1;
