import a_plus_core_1 from "./certs/a_plus_core_1";
import a_plus_core_2 from "./certs/a_plus_core_2";
import network_plus from "./certs/network_plus";
import security_plus from "./certs/security_plus";
import itf_plus from "./certs/itf_plus";
import cloud_plus from "./certs/cloud_plus";
import linux_plus from "./certs/linux_plus";

const COMPITA = {
  id: 1,
  name: "CompTIA",
  certifications: [
    { ...itf_plus },
    { ...a_plus_core_1 },
    { ...a_plus_core_2 },
    { ...network_plus },
    { ...security_plus },
    { ...cloud_plus },
    { ...linux_plus },
  ],
};

export default COMPITA;
