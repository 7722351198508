import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import StandardExam from "./components/StandardExam";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import CertInformation from "../../components/cert-information/CertInformation";

import { startNewExam } from "../../store/reducers/appSlice";

const Exam = () => {
  const dispatch = useDispatch();
  const cert = useSelector((store) => store.app.cert);

  const { examResumed, viewPastExam } = useLocation().state;

  const [custom, setCustom] = useState(false);
  const [topic, setTopic] = useState("");
  const [numQuestions, setNumQuestions] = useState(cert.questions);
  const [timeLimit, setTimeLimit] = useState(cert.time);
  const [instantFeedback, setInstantFeedback] = useState(false);
  const [timed, setTimed] = useState(true);
  const [examStarted, setExamStarted] = useState(
    examResumed || viewPastExam || false
  );

  const [loading, setLoading] = useState(false);

  const fetchQuestions = async () => {
    setLoading(true);
    const url = `https://0sxyh5cksj.execute-api.us-east-1.amazonaws.com/questions?cert=${
      cert.id
    }&exam_id=${cert.exam_id}&version=3.0&topic=${topic}&limit=${
      topic && !numQuestions ? "30" : numQuestions
    }`;

    const { data } = await axios.get(url);
    console.log(data);
    const settings = {
      id: uuidv4(),
      custom: custom,
      instantFeedback: instantFeedback,
      timed: timed,
      timeLimit: timeLimit,
      timeRemaining: timeLimit,
      topic: topic,
      numQuestions: numQuestions,
      cert: cert.name,
      certId: cert.id,
    };

    let questions = data.Items.map((question) => {
      if (question.TYPE !== "true-false") {
        question.ANSWERS = question.ANSWERS.sort(() => Math.random() - 0.5);
      }
      return question;
    });
    dispatch(
      startNewExam({
        settings: settings,
        questions: questions,
      })
    );
    setExamStarted(true);
  };

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  const handleNumQuestionsChange = (event) => {
    if (event.target.value > 120) {
      setNumQuestions(120);
    } else if (event.target.value < 15) {
      setNumQuestions(15);
    } else setNumQuestions(event.target.value);
  };

  const handleTimeLimitChange = (event) => {
    if (event.target.value > 180) {
      setTimeLimit(180 * 60);
    } else if (event.target.value < 5) {
      setTimeLimit(5 * 60);
    } else setTimeLimit(event.target.value * 60);
  };

  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          alignSelf: "center",
          backgroundColor: "#1d2025",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
          marginBottom: 50,
          marginX: "auto",
        }}
      >
        {!examStarted ? (
          <CardContent>
            <Typography
              sx={{ fontSize: 25, textAlign: "center" }}
              gutterBottom
              variant="h4"
              component="div"
            >
              {cert.name}
            </Typography>

            <br />

            <Grid spacing={8} container>
              <Grid item xs={12} sm={5}>
                <Typography
                  sx={{ textAlign: "center" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Customize Parameters:
                </Typography>

                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <RadioGroup
                    aria-labelledby="exam-parameters-label"
                    defaultValue={false}
                    name="exam-parameters-radio-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Standard"
                      onClick={() => {
                        setTimed(true);
                        setInstantFeedback(false);
                        setTopic("");
                        setCustom(false);
                      }}
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Custom"
                      onClick={() => setCustom(true)}
                    />
                  </RadioGroup>
                </FormControl>

                <hr />
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "center",
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Typography style={custom ? {} : { color: "#999" }}>
                        Instant Feedback
                      </Typography>
                    }
                    onChange={() => setInstantFeedback(!instantFeedback)}
                    checked={instantFeedback}
                    disabled={!custom}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Typography style={custom ? {} : { color: "#999" }}>
                        Timed
                      </Typography>
                    }
                    onChange={() => setTimed(!timed)}
                    checked={timed}
                    disabled={!custom}
                  />
                </FormGroup>

                <div>
                  <Box sx={{ minWidth: 120, marginTop: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{ color: !custom ? "gray" : "white" }}
                        id="focus-topic-select-label"
                      >
                        Focus Topic
                      </InputLabel>
                      <Select
                        labelId="focus-topic-select-label"
                        id="focus-topic-select"
                        value={topic}
                        label="Topic"
                        onChange={handleTopicChange}
                        sx={{
                          color: "white",
                          backgroundColor: custom ? "#444" : "#333",
                        }}
                        disabled={!custom}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {cert?.topics?.map((topic) => (
                          <MenuItem key={topic} value={topic} name={topic}>
                            {topic}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <br />
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    id="questions"
                    label="# of Questions (15-120)"
                    variant="outlined"
                    disabled={!custom}
                    onChange={handleNumQuestionsChange}
                    type="number"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: custom ? "white" : "#999",
                        },
                        "&:hover fieldset": {
                          borderColor: custom ? "white" : "#999",
                        },
                        "&.Mui-disabled fieldset": {
                          borderColor: "#999",
                        },
                        color: custom ? "white" : "#999",
                      },
                      "& .MuiInputLabel-root": {
                        color: custom ? "white" : "#999",
                      },
                      "& .MuiInputLabel-root.Mui-disabled": {
                        color: "#999",
                      },
                      width: "100%",
                    }}
                  />

                  <br />
                  <br />
                  <TextField
                    id="timeLimit"
                    label="Time Limit (max 180 minutes)"
                    variant="outlined"
                    disabled={!custom}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: custom ? "white" : "#999",
                        },
                        "&:hover fieldset": {
                          borderColor: custom ? "white" : "#999",
                        },
                        "&.Mui-disabled fieldset": {
                          borderColor: "#999",
                        },
                        color: custom ? "white" : "#999",
                      },
                      "& .MuiInputLabel-root": {
                        color: custom ? "white" : "#999",
                      },
                      "& .MuiInputLabel-root.Mui-disabled": {
                        color: "#999",
                      },
                      width: "100%",
                    }}
                    onChange={handleTimeLimitChange}
                    type="number"
                  />
                </Box>

                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={fetchQuestions}
                    variant="contained"
                    sx={{ marginTop: 2 }}
                  >
                    {loading ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      "Begin Exam"
                    )}
                  </Button>
                </div>

                <br />
                <br />
              </Grid>
              <Grid item xs={12} sm={7}>
                <CertInformation info={cert.info} />
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <StandardExam
            cert={cert}
            instantFeedback={instantFeedback}
            timed={timed}
            timeLimit={timeLimit}
          />
        )}
      </Card>
    </span>
  );
};
export default Exam;
