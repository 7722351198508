import itfPlusSvg from "../../../../assets/comptia-itf.svg";

const itf_plus = {
  id: "itf-plus",
  name: "CompTIA IT Fundamentals+ (FC0-U61)",
  description: "CompTIA IT Fundamentals+",
  icon: itfPlusSvg,
  questions: 75,
  time: 3600,
  exam_id: "FC0-U61",
  topics: [
    "IT-Concepts-and-Terminology",
    "Infrastructure",
    "Applications-and-Software",
    "Software-Development",
    "Database-Fundamentals",
    "Security",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "FC0-U61" },
      { name: "Number of Questions", value: "Maximum of 75" },
      {
        name: "Question Types",
        value: "Multiple Choice",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value: "No prior experience necessary.",
      },
      { name: "Passing Score", value: "650 (on a scale of 100–900)" },
      { name: "Cost of Exam", value: "$138.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      { number: "1.0", name: "IT Concepts and Terminology", percentage: "17%" },
      { number: "2.0", name: "Infrastructure", percentage: "22%" },
      { number: "3.0", name: "Applications and Software", percentage: "18%" },
      {
        number: "4.0",
        name: "Software Development",
        percentage: "12%",
      },
      {
        number: "5.0",
        name: "Database Fundamentals",
        percentage: "11%",
      },
      {
        number: "6.0",
        name: "Security",
        percentage: "20%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official Comptia ITF exam objectives:",
    more_domain_info_link:
      "https://www.comptia.org/training/resources/exam-objectives/comptia-itf-u61-exam-objectives",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link:
          "https://www.totalsem.com/store/category/comptia-it-fundamentals/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link: "https://www.pluralsight.com/paths/comptia-itf-fc0-u61",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Udemy",
        provider_link_available: true,
        provider_link:
          "https://www.udemy.com/course/comptia-it-fundamentals-fco-u61-complete-course-exam/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Certification Cynergy",
        provider_link_available: true,
        provider_link:
          "https://www.youtube.com/watch?v=6NimRqfLCZA&list=PLT8yTjyUGL2z0-SKmAgv31bxVmbCHODXF",
        free: true,
        video_content: true,
        practice_exams: false,
      },
    ],
  },
};

export default itf_plus;
