import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import QuestionSummaryItem from "./questions/QuestionSummaryItem";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import colors from "../../../utils/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import NextPlanIcon from "@mui/icons-material/NextPlan";

const ExamSummary = ({
  computeColor,
  handleClickOpenReportDialog,
  pastExamIndex,
}) => {
  const exam = useSelector((store) => store.app.pastExams[pastExamIndex]);
  const answerFilters = ["All", "Correct", "Incorrect", "Skipped"];
  const domainFilters = [
    "All",
    ...new Set(exam.questions.map((question) => question.DOMAIN)),
  ];
  const numCorrect = exam.settings.numCorrect;
  const [answerFilter, setAnswerFilter] = useState("All");
  const [domainFilter, setDomainFilter] = useState("All");
  const [questions, setQuestions] = useState(exam.questions);
  const [pieData, setPieData] = useState([]);

  const updateAnswerFilter = (event) => {
    switch (event.target.value) {
      case "Correct":
        setQuestions(exam.questions.filter((question) => question.CORRECT));
        setAnswerFilter("Correct");
        break;
      case "Incorrect":
        setQuestions(exam.questions.filter((question) => !question.CORRECT));
        setAnswerFilter("Incorrect");
        break;
      case "Skipped":
        setQuestions(exam.questions.filter((question) => question.SKIPPED));
        setAnswerFilter("Skipped");
        break;
      default:
        setQuestions(exam.questions);
        setAnswerFilter("All");
        break;
    }
  };

  const updateDomainFilter = (event) => {
    if (event.target.value === "All") {
      setQuestions(exam.questions);
      setDomainFilter("All");
    } else {
      setQuestions(
        exam.questions.filter(
          (question) => question.DOMAIN === event.target.value
        )
      );
      setDomainFilter(event.target.value);
    }
  };

  useEffect(() => {
    let domains = [];
    questions.forEach((question) => {
      if (!domains.includes(question.DOMAIN)) {
        domains.push(question.DOMAIN);
      }
    });

    let keys = Object.keys(colors);

    const pieData = domains.map((domain, index) => ({
      title: domain,
      value: questions.filter((question) => question.DOMAIN === domain).length,
      color: colors[keys[index + 9]],
      correct: questions
        .filter((question) => question.DOMAIN === domain)
        .filter((question) => question.CORRECT).length,
      incorrect:
        questions
          .filter((question) => question.DOMAIN === domain)
          .filter((question) => !question.CORRECT).length -
        questions
          .filter((question) => question.DOMAIN === domain)
          .filter((question) => question.SKIPPED).length,
      skipped: questions
        .filter((question) => question.DOMAIN === domain)
        .filter((question) => question.SKIPPED).length,
    }));

    setPieData(pieData);
  }, [numCorrect]);

  return (
    <Box sx={{ margin: 1 }}>
      <Typography
        sx={{ fontSize: 20, textAlign: "center" }}
        variant="body2"
        component="div"
      >
        Thank you for taking an exam!
      </Typography>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <Box sx={{ margin: 2 }}>
          <Typography sx={{ textAlign: "center" }} variant="h4" component="div">
            Exam Breakdown
          </Typography>

          <TableContainer sx={{ marginTop: 3, marginBottom: 3 }}>
            <Table aria-label="exam-breakdown">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Key</TableCell>
                  <TableCell sx={{ color: "white" }}>Domain</TableCell>
                  <TableCell sx={{ color: colors.accentGreen }}>
                    <CheckIcon />
                  </TableCell>
                  <TableCell sx={{ color: colors.accentCyan }}>
                    <NextPlanIcon />
                  </TableCell>
                  <TableCell sx={{ color: colors.accentRed }}>
                    <CloseIcon />
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pieData.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ color: "white" }}
                      component="th"
                      scope="row"
                    >
                      <Box
                        sx={{
                          height: "20px",
                          backgroundColor: data.color,
                        }}
                      ></Box>
                    </TableCell>
                    <TableCell
                      sx={{ color: "white" }}
                      component="th"
                      scope="row"
                    >
                      {data.title.split("-").join(" ")}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      {data.correct}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      {data.skipped}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      {data.incorrect}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="center">
                      {data.value}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ color: "white" }} component="th" scope="row">
                    Total -{" "}
                    {((numCorrect / exam.questions.length) * 100).toFixed(2)}%
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    {numCorrect}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    {
                      exam.questions.filter((question) => question.SKIPPED)
                        .length
                    }
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    {exam.questions.length -
                      numCorrect -
                      exam.questions.filter((question) => question.SKIPPED)
                        .length}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    {exam.questions.length}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <PieChart
            data={pieData}
            label={({ dataEntry }) =>
              `${((dataEntry.value / exam.questions.length) * 100).toFixed(2)}%`
            }
            labelStyle={{
              fontSize: "5px",
              fontFamily: "sans-serif",
              fill: "#fff",
            }}
            labelPosition={65}
          />
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ width: "40%" }}>
          <InputLabel sx={{ color: "white" }} id="answerFilter">
            Answer Filter
          </InputLabel>
          <Select
            labelId="answerFilter"
            id="answer-filter-select"
            value={answerFilter}
            onChange={updateAnswerFilter}
            sx={{ color: "white" }}
          >
            {answerFilters.map((answerFilter) => (
              <MenuItem key={answerFilter} value={answerFilter}>
                {answerFilter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "40%" }}>
          <InputLabel sx={{ color: "white" }} id="filter">
            Domain Filter
          </InputLabel>
          <Select
            labelId="filter"
            id="filter-select"
            value={domainFilter}
            label="Topic"
            onChange={updateDomainFilter}
            sx={{ color: "white" }}
          >
            {domainFilters.map((domainFilter) => (
              <MenuItem key={domainFilter} value={domainFilter}>
                {domainFilter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <br />
      {questions.map((question) => (
        <QuestionSummaryItem
          key={question.ID}
          question={question}
          computeColor={computeColor}
          handleClickOpenReportDialog={handleClickOpenReportDialog}
          index={exam.questions.indexOf(question)}
        />
      ))}
    </Box>
  );
};
export default ExamSummary;
