export default {
  primary: "#1d2025", // Primary: Dark muted gray-black
  primaryLight: "#282c31", // Slightly lighter version of primary
  primaryDark: "#121417", // Darker version of primary
  secondary: "#22272b", // Secondary: Muted charcoal
  secondaryLight: "#3b4147", // Slightly lighter version of secondary
  secondaryDark: "#1a1e21", // Darker version of secondary

  font: "#ffffff", // Standard font color
  grayLight: "#4a4d51", // Light gray, muted and complementary
  gray: "#3c3f43", // Standard gray
  white: "#ffffff", // Standard white
  accentGreen: "#6c9a8b", // Muted teal green (good for accents)
  accentRed: "#a55b5b", // Muted earthy red
  accentBlue: "#4e6e91", // Muted blue-gray
  accentYellow: "#c2a55b", // Muted golden yellow
  accentPurple: "#7b6e91", // Muted purple
  accentOrange: "#c9895b", // Muted burnt orange
  accentCyan: "#5b9ba6", // Muted cyan
};
