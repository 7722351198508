import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CertTile from "./CertTile";
import Grid from "@mui/material/Grid";

const ExamProvider = ({ provider }) => {
  return (
    <Card
      sx={{
        maxWidth: 750,
        backgroundColor: "#202025",
        color: "white",
        borderRadius: 3,
        marginBottom: 3,
        border: "1px solid #777",
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="h6">
          {provider.name}
        </Typography>

        <Grid container spacing={1}>
          {provider.certifications.map((cert) => (
            <CertTile key={cert.id + cert.name} cert={cert} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExamProvider;
