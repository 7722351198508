import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import colors from "../../../utils/colors";

const QuestionGrid = ({
  questions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
}) => {
  return (
    <Grid container>
      {questions.map((question, index) => (
        <Box
          onClick={() => setCurrentQuestionIndex(index)}
          sx={{
            width: 25,
            height: 25,
            borderWidth: 1,
            borderColor: "white",
            borderStyle: "solid",
            borderRadius: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 15,
            fontWeight: "bold",
            cursor: "pointer",
            margin: 1,
            color: index === currentQuestionIndex ? "#333" : "#white",
            backgroundColor:
              index === currentQuestionIndex
                ? colors.white
                : question.ANSWERED
                ? colors.accentBlue
                : question.SKIPPED
                ? colors.grayLight
                : colors.secondary,

            "&:hover": {
              backgroundColor: "white",
              color: "#333",
            },
          }}
          key={question.ID}
        >
          {index + 1}
        </Box>
      ))}
    </Grid>
  );
};

export default QuestionGrid;
