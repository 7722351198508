import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          maxWidth: 800,
          backgroundColor: "#1d2025",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            component="div"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            About CertNova 🎓
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            Welcome to CertNova, a platform dedicated to helping you succeed in
            your certification journey. At CertNova, we’re committed to
            providing free, ad-free, and paywall-free resources that empower
            individuals to reach their certification goals. Our mission is to
            offer comprehensive, reliable, and accessible study materials so you
            can focus on what matters—your learning. We understand that
            preparing for certification exams can be challenging. That’s why
            we’ve designed our platform to offer a wide variety of practice
            exams and study resources that cover multiple certification topics.
            Whether you're preparing for IT, project management, or other
            professional certifications, CertNova has the tools to help you
            succeed. Each of our practice exams mirrors the real exam format and
            content, giving you the most effective preparation possible.
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            We also believe that learning is more powerful when it’s done
            together. Be sure to join our Discord community where you can
            connect with like-minded learners, ask questions, share resources,
            and grow together.
          </Typography>
          <Typography variant="body2" color="white" sx={{ marginBottom: 2 }}>
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: 600,
              }}
              to="https://discord.gg/3JPurAQeJx"
            >
              👉 Join Our Discord
            </Link>
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            CertNova is made possible by our supportive community. If you’d like
            to help us continue to offer free, high-quality resources, consider
            supporting us on Patreon!
          </Typography>
          <Typography variant="body2" color="white" sx={{ marginBottom: 2 }}>
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: 600,
              }}
              to="https://www.patreon.com/certnova"
            >
              👉 Support us on Patreon
            </Link>
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: 2 }}>
            Thank you for choosing CertNova. We’re excited to be a part of your
            certification journey and are here to support you every step of the
            way!
          </Typography>
        </CardContent>
      </Card>
    </span>
  );
};

export default About;
