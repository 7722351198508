import securityPlusSvg from "../../../../assets/comptia-securityplus.svg";

const security_plus = {
  id: "security-plus",
  name: "CompTIA Security+",
  description: "CompTIA Security+",
  icon: securityPlusSvg,
  questions: 90,
  time: 5400,
  exam_id: "SY0-701",
  topics: [
    "General-Security-Concepts",
    "Threats-Vulnerabilities-and-Mitigations",
    "Security-Architecture ",
    "Security-Operations",
    "Security-Program-Management-and-Oversight",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "SY0-701" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value:
          "CompTIA Network+ and two years of experience working in a security/ systems administrator job role",
      },
      { name: "Passing Score", value: "750 (on a scale of 100-900)" },
      { name: "Cost of Exam", value: "$404.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      {
        number: "1.0",
        name: "General Security Concepts",
        percentage: "12%",
      },
      {
        number: "2.0",
        name: "Threats, Vulnerabilities and Mitigations",
        percentage: "22%",
      },
      {
        number: "3.0",
        name: "Security Architecture",
        percentage: "18%",
      },
      {
        number: "4.0",
        name: "Security Operations",
        percentage: "28%",
      },
      {
        number: "5.0",
        name: "Security Program Management and Oversight",
        percentage: "20%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA Network+ N10-008 exam objectives:",
    more_domain_info_link:
      "https://assets.ctfassets.net/82ripq7fjls2/6TYWUym0Nudqa8nGEnegjG/0f9b974d3b1837fe85ab8e6553f4d623/CompTIA-Security-Plus-SY0-701-Exam-Objectives.pdf",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/security-certification/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Professor Messer",
        provider_link_available: true,
        provider_link:
          "https://www.professormesser.com/security-plus/sy0-701/sy0-701-video/sy0-701-comptia-security-plus-course/",
        free: true,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/comptia-security",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/comptia-security-sy0-701",
        free: false,
        video_content: true,
        practice_exams: true,
      },
    ],
  },
};

export default security_plus;
