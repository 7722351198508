import { useState } from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import ExamProvider from "./components/ExamProvider";
import PausedExamTile from "./components/PausedExamTile";
import PastExamTile from "./components/PastExamTile";
import PROVIDERS from "../../data/providers/providers";
import SVGComponent from "../../components/images/SVGComponent";
import discordLogo from "../../assets/discord_logo.svg";

const Main = () => {
  const pastExams = useSelector((store) => store?.app?.pastExams);
  const pausedExams = useSelector((store) => store?.app?.pausedExams);

  const [showMorePaused, setShowMorePaused] = useState(false);
  const [showMorePast, setShowMorePast] = useState(false);
  const [hidePastExams, setHidePastExams] = useState(false);

  const toggleShowMorePaused = () => {
    setShowMorePaused(!showMorePaused);
  };

  const toggleShowMorePast = () => {
    setShowMorePast(!showMorePast);
  };

  const toggleHidePastExams = () => {
    setHidePastExams(!hidePastExams);
  };

  return (
    <span style={{ minHeight: "87vh" }}>
      <Card
        sx={{
          maxWidth: 800,
          backgroundColor: "#1d2025",
          color: "white",
          borderRadius: 3,
          marginTop: 3,
        }}
      >
        <CardContent>
          <Typography
            sx={{ textAlign: "center" }}
            gutterBottom
            variant="h4"
            component="div"
          >
            Welcome to CertNova
          </Typography>
          <br />
          <Typography variant="body1" color="white">
            Welcome to CertNova! 🎓
          </Typography>
          <Typography variant="body2" color="white">
            CertNova is your go-to resource for free practice exams to help you
            preprare for a wide variety of certifications. We’re committed to
            keeping this platform ad-free and paywall-free, so you can focus on
            learning without distractions. Whether you’re diving into tech
            certifications or exploring new fields, we’re here to support your
            journey.
          </Typography>
          <br />
          <Typography variant="body1" color="white">
            Join Our Community!
          </Typography>
          <Typography variant="body2" color="white">
            Studying is always better with others. Connect with like-minded
            learners, share resources, report problems with the site, and get
            your questions answered by joining our growing Discord community!
            We’d love to see you there!
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://discord.gg/3JPurAQeJx"
            >
              <SVGComponent
                src={discordLogo}
                style={{ marginRight: 10, height: 30 }}
              />{" "}
            </Link>
          </Typography>
          <br />
          <Typography variant="body1" color="white">
            Let's keep growing!
          </Typography>
          <Typography variant="body2" color="white">
            CertNova is a passion project, and we’re proud to keep it accessible
            for everyone. If you’d like to help us stay ad-free and free of
            paywalls, consider supporting us on Ko-Fi. Your support helps us
            grow and reach more learners like you!
          </Typography>
          <Typography variant="body2" color="white">
            <a
              href="https://ko-fi.com/Y8Y315IJ3C"
              target="_blank"
              rel="noreferrer"
            >
              <img
                height="36"
                style={{ border: "0px", height: "36px", marginTop: 10 }}
                src="https://storage.ko-fi.com/cdn/kofi6.png?v=6"
                border="0"
                alt="Buy Me a Coffee at ko-fi.com"
              />
            </a>
          </Typography>
          <br />
          <Typography variant="body1" color="white">
            Thanks for visiting CertNova, and happy studying! Let’s succeed
            together!
          </Typography>
          <br />
          {pastExams && pastExams.length > 0 && (
            <span>
              {!hidePastExams && (
                <Typography
                  sx={{ textAlign: "center" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Past Exams
                </Typography>
              )}
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                container
                spacing={1}
              >
                {!hidePastExams &&
                  pastExams.map((exam, index) => {
                    if (!showMorePast && index > 2) return null;
                    return (
                      <PastExamTile
                        key={exam.settings.id}
                        exam={exam}
                        hidePastExams={hidePastExams}
                        setHidePastExams={setHidePastExams}
                      />
                    );
                  })}
              </Grid>
              <br />
              {pastExams && pastExams.length > 0 && (
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={toggleHidePastExams}
                    variant="outlined"
                    sx={{ color: "white" }}
                  >
                    {hidePastExams ? "Show" : "Hide"}
                  </Button>

                  {pastExams.length > 3 && (
                    <Button
                      onClick={toggleShowMorePast}
                      variant="outlined"
                      sx={{ color: "white" }}
                    >
                      {showMorePast ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </span>
              )}
              <hr />
            </span>
          )}

          {pausedExams && pausedExams.length > 0 && (
            <span>
              <Typography
                sx={{ textAlign: "center" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                Paused Exams:
              </Typography>
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                container
                spacing={1}
              >
                {pausedExams.map((exam, index) => {
                  if (!showMorePaused && index > 2) return null;
                  return <PausedExamTile key={exam.settings.id} exam={exam} />;
                })}
                <br />
              </Grid>
              <br />
              {pausedExams && pausedExams.length > 3 && (
                <Button
                  onClick={toggleShowMorePaused}
                  variant="outlined"
                  sx={{ color: "white" }}
                >
                  {showMorePaused ? "Show Less" : "Show More"}
                </Button>
              )}
              <hr />
            </span>
          )}
          <Typography
            sx={{ textAlign: "center" }}
            gutterBottom
            variant="h5"
            component="div"
          >
            Select a certification
          </Typography>

          {PROVIDERS.map((provider) => (
            <ExamProvider key={provider.id} provider={provider} />
          ))}
        </CardContent>
      </Card>
    </span>
  );
};

export default Main;
