import linuxPlusSvg from "../../../../assets/comptia-linuxplus.svg";

const linux_plus = {
  id: "linux-plus",
  name: "CompTIA Linux+ (XK0-005)",
  description: "CompTIA Linux+",
  icon: linuxPlusSvg,
  questions: 90,
  time: 5400,
  exam_id: "XK0-005",
  topics: [
    "System-Management",
    "Security",
    "Scripting-Containers-and-Automation",
    "Troubleshooting",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "XK0-005" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value: `12 months of hands-on experience working with Linux servers, as well as A+, Network+, and Server+ or similar certifications and/or knowledge`,
      },
      { name: "Passing Score", value: "720 (on a scale of 100–900)" },
      { name: "Cost of Exam", value: "$369.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      {
        number: "1.0",
        name: "System Management",
        percentage: "32%",
      },
      { number: "2.0", name: "Security", percentage: "21%" },
      {
        number: "3.0",
        name: "Scripting Containers and Automation",
        percentage: "23%",
      },
      {
        number: "4.0",
        name: "Operations and Support",
        percentage: "19%",
      },
      {
        number: "5.0",
        name: "Troubleshooting",
        percentage: "28%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA Linux+ (XK0-005) exam objectives:",
    more_domain_info_link:
      "https://partners.comptia.org/docs/default-source/resources/comptia-linux-xk0-005-exam-objectives-(1-0)",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/store/category/comptia-linux/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link: "https://www.diontraining.com/courses/comptia-linux",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Udemy",
        provider_link_available: true,
        provider_link: "https://www.udemy.com/course/comptia-linux/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
    ],
  },
};

export default linux_plus;
