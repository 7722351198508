import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  deletePausedExam,
  resumePausedExam,
} from "../../../store/reducers/appSlice";

const PausedExamTile = ({ exam }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resumeExam = () => {
    dispatch(resumePausedExam(exam.settings.id));
    navigate(`/exam/${exam.settings.certId}`, { state: { examResumed: true } });
  };

  const deleteExam = () => {
    dispatch(deletePausedExam(exam.settings.id));
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 225,
        height: 175,
        backgroundColor: "#22272b",
        color: "white",
        borderRadius: 3,
        justifyContent: "space-between",
        alignItems: "space-between",
        margin: 1,
        ":hover": {
          cursor: "pointer",
          boxShadow: 20,
        },
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <Typography gutterBottom variant="body1">
          {exam.settings.cert}
        </Typography>

        <Typography gutterBottom variant="body1">
          Question: {exam.settings.currentQuestionIndex} /{" "}
          {exam.settings.numQuestions}
        </Typography>

        <Typography gutterBottom variant="body1">
          Time Remaining:{" "}
          {Math.floor((exam.settings.timeRemaining / 60).toFixed(2))}:
          {exam.settings.timeRemaining % 60 < 10
            ? "0" + (exam.settings.timeRemaining % 60)
            : exam.settings.timeRemaining % 60}
        </Typography>

        <Box>
          <Button onClick={resumeExam} variant="contained" color="success">
            Resume
          </Button>

          <Button
            onClick={deleteExam}
            variant="contained"
            color="error"
            sx={{ marginLeft: 1 }}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PausedExamTile;
