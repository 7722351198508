import cloudPlusSVG from "../../../../assets/comptia-cloudplus.svg";

const cloud_plus = {
  id: "cloud-plus",
  name: "CompTIA Cloud+ (CV0-004)",
  description: "CompTIA Cloud+",
  icon: cloudPlusSVG,
  questions: 90,
  time: 5400,
  exam_id: "CV0-004",
  topics: [
    "Cloud-Architecture-and-Design",
    "Security",
    "Deployment",
    "Operations-and-Support",
    "Troubleshooting",
  ],
  info: {
    exam_details: [
      { name: "Exam ID", value: "CV0-004" },
      { name: "Number of Questions", value: "Maximum of 90" },
      {
        name: "Question Types",
        value: "Multiple Choice / Performance Based",
      },
      { name: "Length of Test", value: "90 Minutes" },
      {
        name: "Recommended Experience",
        value: `• At least 2—3 years of work experience in IT systems administration or IT networking
        • CompTIA Network+ and Server+ or equivalent knowledge
        • Familiarity with any major hypervisor technology for server virtualization
        • Knowledge of cloud service models
        • Knowledge of IT service management
        • Hands-on experience with at least one public or private cloud IaaS platform`,
      },
      { name: "Passing Score", value: "750 (on a scale of 100–900)" },
      { name: "Cost of Exam", value: "$369.00" },
      { name: "Validity", value: "3 years" },
    ],
    domains: [
      {
        number: "1.0",
        name: "Cloud Architecture and Design",
        percentage: "13%",
      },
      { number: "2.0", name: "Security", percentage: "20%" },
      { number: "3.0", name: "Deployment", percentage: "23%" },
      {
        number: "4.0",
        name: "Operations and Support",
        percentage: "22%",
      },
      {
        number: "5.0",
        name: "Troubleshooting",
        percentage: "22%",
      },
    ],
    more_domain_info_available: true,
    more_domain_info_message:
      "For more information, please find the official CompTIA Cloud+ CV0-004 exam objectives:",
    more_domain_info_link:
      "https://partners.comptia.org/docs/default-source/resources/comptia-cloud-cv0-003-exam-objectives-(1-0)",
    study_resources: [
      {
        provider: "Mike Myers",
        provider_link_available: true,
        provider_link: "https://www.totalsem.com/store/category/comptia-cloud/",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Jason Dion",
        provider_link_available: true,
        provider_link:
          "https://www.diontraining.com/courses/comptia-cloud-essentials",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "PluralSight",
        provider_link_available: true,
        provider_link:
          "https://www.pluralsight.com/paths/comptia-cloud-cv0-003",
        free: false,
        video_content: true,
        practice_exams: true,
      },
      {
        provider: "Udemy",
        provider_link_available: true,
        provider_link:
          "https://www.udemy.com/course/comptia-cloud-certification-exam-prep-cloud-mastery",
        free: false,
        video_content: true,
        practice_exams: true,
      },
    ],
  },
};

export default cloud_plus;
